import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const Loader = ({ size, color }) => {

  return (
    <ClipLoader
      size={size}
      color={color}
      loading={true}
    />
  );
};

export default Loader;
