import React from 'react';

import {  useStaticQuery, graphql } from 'gatsby';

import Layout from './../components/Layout';
import Seo from './../components/Seo';
import WhitePaperForm from '../components/WhitePaperForm/WhitePaperForm';
import whitePaperImage2 from './../assets/images/white-paper-2.png';
import whitePaperImage from './../assets/images/white-paper.png';
import whitePaperStyles from './../styles/white-paper.module.scss';
import BlogPostPreview from './../components/BlogPostPreview/BlogPostPreview';

const WhitePaper = () => {
  const articlesData = useStaticQuery(graphql`
    query MoreArticlesQuery {
      allMarkdownRemark(filter: {fields: {collectionType: {eq: "blog"}}}, limit: 3, sort: {fields: frontmatter___date, order: DESC}) {
        edges {
          node {
            id
            frontmatter {
              category
              image
              title
              category
              description
              date
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  const articles = articlesData.allMarkdownRemark.edges.map(article => {
    return (
      <BlogPostPreview
        data={article.node.frontmatter}
        slug={article.node.fields.slug}
        key={article.node.frontmatter.title}
      />
    );
  });

  return (
    <Layout>
      <Seo title={'Co-sourcing White Paper | TechPods'}/>
      <main>
        <section className={whitePaperStyles.intro}>
          <div>
            <h1 className="primary-title" >Curious about <br /> Co-sourcing?</h1>
            <p className="important-text">You came to the right place. <br /> Download our free white paper below:</p>
          </div>
          <img src={whitePaperImage} alt="Co-sourcing" title="Co-sourcing"/>
        </section>
        <section className={whitePaperStyles.formContainer}>
          <div className={whitePaperStyles.info}>
            <img src={whitePaperImage2} alt="Co-sourcing" title="Co-sourcing"/>
            <p className="regular-text">It seems almost every Tom, Dick, and Harry is offering IT outsourcing services nowadays, and it’s becoming increasingly difficult to differentiate the reliable companies from the not so reliable.</p>
            <p className="regular-text">That is why we continue to invest endless hours in researching, and building useful tools to help companies better understand the different types of IT outsourcing, and more specifically in this case, Co-sourcing. </p>
            <p className="regular-text">Our Co-sourcing white paper is the first of its kind, covering:</p>
            <ul className="regular-text">
              <li>What is Co-sourcing?</li>
              <li>The benefits of implementing a Co-sourcing strategy</li>
              <li>Co-sourcing VS outsourcing </li>
            </ul>
            <p className="regular-text">...AND MORE! </p>
            <p className="regular-text">Please enter your details to receive your copy. </p>
          </div>
          <WhitePaperForm backgroundColor={"#F0F4F7"} />
        </section>
        <section className={whitePaperStyles.moreArticles}>
          <h2 className="secondary-title">More articles</h2>
          <hr />
          <div className={whitePaperStyles.moreArticlesGrid }>
            {articles}
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default WhitePaper;