import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

import whitePaperFormStyles from './white-paper-Form.module.scss';
import Loader from './../Loader';
import { sendAnalitycsThankYouEvent } from './../../common/utils';


const WhitePaperForm = ({ backgroundColor }) => {
  const [ isRobot, setIsRobot ] = useState(true);
  const [ responseMessage, setResponseMessage ] = useState('');
  const { register, handleSubmit, errors } = useForm();
  const [ isLoading, setIsLoading ] = useState(false);

  const onSubmit = async (data, e) => {

    if(!isRobot) {
      window.open('https://drive.google.com/uc?export=download&id=1KhPAr64qou6OJSYc-zEjA8yF3DmUGtby');
      setIsLoading(true)
      const res = await axios.post('/gethandbook', data);
      setResponseMessage(res.data.success ? 'Thank you! Please check your email.' : 'Oops, something wrong happened! Please try again later.');
      res.data.success && sendAnalitycsThankYouEvent('white-paper', 'white_paper_form');
    } else {
      alert('Please confirm that you are not a robot!')
    }
  };

  const verify = (response) => {
    if (response) {
      setIsRobot(false)
    }
  };

  return (
    <div className={whitePaperFormStyles.container} style={{ backgroundColor }}>
      {
        !isLoading ?
        <>
          <h2 className="tertiary-title">Download the handbook here</h2>
          <form onSubmit={handleSubmit(onSubmit)} className={whitePaperFormStyles.form}>
            <fieldset>
              <div className={whitePaperFormStyles.field}>
                <label htmlFor="email">E-mail</label>
                <input type="email" name="email" id="email" autoComplete="off" ref={register({ required: true })} />
                {errors.email && <span>This field is required</span>}
              </div>
              <div className={whitePaperFormStyles.field}>
                <label htmlFor="name">Full Name</label>
                <input type="text" name="name" id="name" autoComplete="off" ref={register({ required: true })} />
                {errors.name && <span>This field is required</span>}
              </div>
              <div className={whitePaperFormStyles.field}>
                <label htmlFor="jobTitle">Job title</label>
                <input type="text" name="jobTitle" id="jobRitle" autoComplete="off" ref={register({ required: true })} />
                {errors.jobTitle && <span>This field is required</span>}
              </div>
              <div className={whitePaperFormStyles.field}>
                <label htmlFor="company">Company name</label>
                <input type="text" name="company" id="company" autoComplete="off" ref={register({ required: true })} />
                {errors.company && <span>This field is required</span>}
              </div>
            </fieldset>
            <div className={whitePaperFormStyles.actions}>
              <ReCAPTCHA
                onChange={verify}
                name="captcha"
                sitekey="6LcpUskZAAAAAKy95jNNAJVq9VJMoV6JPWFuNuY9"
              />
              <button type="submit" className="primary-btn">Submit</button>
            </div>
          </form>
        </> :
        <div className={whitePaperFormStyles.responseContainer}>
          {
            responseMessage ? 
            <h2 className="tertiary-title">{responseMessage}</h2> :
            <Loader color="#485D70" size={100} />
          }
        </div>
      }
    </div>
  );
};

export default WhitePaperForm;